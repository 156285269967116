import ashBeforeAfter from '../../images/ash/before-after-ash.png'
import ashHeader from '../../images/ash/header-ash.png'
import lawnBuddy from '../../images/lawnbuddy/lawnbuddy.png'


const Blog = [
    // {
    //   id: '',
    //   title: '',
    //   subtitle: '',
    //   date: '',
    //   img: {
    //     url: '',
    //     alt: ''
    //   },
    //   content: [
    //     {
    //       heading: '',
    //       paragraph: [
    //         ''
    //       ],
    //       list: [],
    //       img: {
    //         url: '',
    //         alt: '',
    //         caption: ''
    //       }
    //     }
    //   ]
    // },
    {
      id: 'chainlink',
      title: 'What is Chainlink?',
      subtitle: '',
      date: '2022',
      img: {
        url: 'https://assets-global.website-files.com/5f6b7190899f41fb70882d08/5f85cf0e9a917f5c41b4cca5_homepage-hero-2880-min.png',
        alt: ''
      },
      content: [
        {
          heading: '',
          paragraph: [
            ''
          ],
          list: [],
          img: {
            url: '',
            alt: '',
            caption: ''
          }
        }
      ]
    },
    // {
    //     id: 'lawn-buddy',
    //     link: 'https://docs.google.com/presentation/d/1Tj2GhPbRmkwM6uDQ6Qs4VYiDr6QqGMSk5nqlOybGEkM/edit?usp=sharing',
    //     title: 'Heuristic evaluation for Lawn Buddy',
    //     subtitle: 'Web + Mobile',
    //     date: 'August 2021',
    //     img: {
    //         url: lawnBuddy,
    //         alt: 'lawn buddy logo'
    //     },
    //     content: [{
    //             heading: 'Overview',
    //             paragraph: [
    //                 'Lawn Buddy is a tech startup based in the midwest that provides tools to manage independent Lawn and Landscaping businesses. In this project, I was tasked to design a new feature for their mobile and web based applications.'
    //             ],
    //             list: [],
    //             img: {
    //                 url: '',
    //                 alt: '',
    //                 caption: ''
    //             }
    //         },
    //         {
    //             heading: 'Heuristic Evaluation',
    //             paragraph: [
    //                 'Heuristic evaluation is an approach to problem solving by reaching an immediate goal that’s not guaranteed to be perfect or optimal. I used this method to analyze and evaluate Lawn Buddy’s user interface design where I compared the web and mobile products against Jakob Neilsen’s 10 usability heuristics.'
    //             ],
    //             list: [],
    //             img: {
    //                 url: '',
    //                 alt: '',
    //                 caption: ''
    //             }
    //         }
    //     ]
    // },
    // {
    //     id: 'confex',
    //     link: 'https://miikogonzaga.github.io/test/',
    //     title: 'Creating a simple dropdown component',
    //     subtitle: 'American Society of Hematology',
    //     date: 'July 2021',
    //     img: {
    //         url: ashHeader,
    //         alt: 'ash header'
    //     },
    //     content: [{
    //             heading: 'Overview:',
    //             paragraph: [
    //                 'The American Society of Hematology is presenting their 63rd Annual Meeting and Exposition in August, 2021. Their FAQ (frequently asked questions) page needed a redesign due to content overload on the page.'
    //             ],
    //             list: [],
    //             img: {
    //                 url: '',
    //                 alt: '',
    //                 caption: ''
    //             }
    //         },
    //         {
    //             heading: 'Process:',
    //             paragraph: [
    //                 'After conducting a brief heuristic evaluation, I believe aesthetic and minimal design would benefit the page and its users. Due to content overload on the landing page, certain dialogue that’s competing for visibility could potentially be hidden. This would minimize content overload for the user. I decided to hide the extra content using a dropdown toggle system.'
    //             ],
    //             list: [],
    //             img: {
    //                 url: ashBeforeAfter,
    //                 alt: 'ash before and after',
    //                 caption: 'Before (left) | After (right)'
    //             }
    //         },
    //         {
    //             heading: 'Design + Solution:',
    //             paragraph: [
    //                 'The design was super easy since I cloned their existing system in their current style guide. For the structure, I updated the HTML and created a <div> with two children elements that consisted of an <a> anchor tag and <span> elements. Two CSS class selectors were added to the <div> and <span> class respectively which were named faq-question and faq-answer.',
    //                 'Once those elements were in place and can be selected, I wrote a couple of lines of jQuery code to toggle between each individual question and answer. The logic consisted of targeting the click event of the <a> anchor tag and toggling the <span> element which is hidden on page load. This resulted in a simple dropdown toggle to hide extra dialogue on the landing page which would lead to a better user experience.'
    //             ],
    //             list: [],
    //             img: {
    //                 url: '',
    //                 alt: '',
    //                 caption: ''
    //             }
    //         }
    //     ]
    // }
]

export default Blog