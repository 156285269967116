import React from "react";
import './Home.scss';
import home from '../Data/Home';
import CaseStudies from '../CaseStudies/CaseStudies'
import Work from '../Work/Work'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

// import city from '../../images/city.png'

export default function Home() {
    return (
        <div className="home max-1000">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="description" content="Web Development Portfolio by Miko Gonzaga" />
                <title>Portfolio - Miko Gonzaga</title>
            </Helmet>

            <div className="header">
              <div className="name">
                {/* <img src="https://media.licdn.com/dms/image/D5603AQGbIX_XxuXNeA/profile-displayphoto-shrink_400_400/0/1676587661122?e=1681948800&v=beta&t=TD2tUzRo5pLfdfQUq_ZGAKCOqSYCdLynDnaoUvh4fWM" alt="Miko Gonzaga" /> */}
                <h1>{home.name} <br/> {home.last}</h1>
              </div>
              <div className="nav-about">
                <Link to="/about">About</Link>
              </div>
            </div>

            {/* <p style={{ maxWidth: 610 }}>
              {home.pitch}
              <br/><br/>
            </p> */}

            <div className="animate fadeIn">
              <Work />
            </div>

            <br/>

            <div className="animate fadeIn">
              <CaseStudies />
            </div>

            <br/>

            {/* <img src={city} alt="city landscape" /> */}
        </div>
    )
}
