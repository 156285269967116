import React from 'react';
import CaseStudies from '../Data/CaseStudies';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

export default function Post({ match }) {
  const caseStudy = CaseStudies.find(({ id }) => id == match.params.caseStudyId)

  return (
    <div className="post">
      <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content="Web Development Case Studies by Miko Gonzaga" />
          <title>{caseStudy.title} - Web Development Case Studies by Miko Gonzaga</title>
      </Helmet>

      <nav className="max-800" style={{ paddingTop: 15, paddingBottom: 15 }}>
        <div>
          <Link to="/">← Back</Link>
        </div>
      </nav>

      {caseStudy.intro.map(({
        img,
        postImg,
        duration,
        role,
        team,
        location }) =>
          <section className="intro animate fadeIn">
            <div className="header-intro max-800">
              <span className={`${caseStudy.id}`}></span>
              <h2>{caseStudy.title}</h2>
              <h4>{caseStudy.subtitle}</h4>
            </div>

            <div className="max-1200">
              { caseStudy.postImg === '' ? null :
                <div>
                  <img src={caseStudy.postImg.url} alt={caseStudy.postImg.alt} />
                  <small>{caseStudy.postImg.caption}</small>
                </div> }
            </div>

            <div className="sub-intro max-800">
              <div>
                <h3>Team</h3>
                { Array.isArray(team) ? team.map(team => <p>{team}</p>) : null }
              </div>

              <div>
                <h3>Role</h3>
                { Array.isArray(role) ? role.map(role => <p>{role}</p>) : null }
              </div>

              <div>
                <h3>Duration</h3>
                { duration === '' ? null : <p>{duration}</p> }
              </div>

              <div>
                <h3>Location</h3>
                { location === '' ? null : <p>{location}</p> }
              </div>
            </div>
          </section>
      )}

      {caseStudy.content.map(({
        heading,
        paragraph,
        img,
        listHeading,
        list }) =>
          <section className="content animate fadeIn">
            <div className="max-800">
              { heading === '' ? null : <h2>{heading}</h2> }
            </div>

            <div className="max-1200">
              { img.url === '' ? null :
                <div>
                  <img src={img.url} alt={img.alt} />
                  <small>{img.caption}</small>
                </div> }
            </div>

            <div className="max-800">
              { Array.isArray(paragraph) ?
                  paragraph.map(paragraph =>
                    <p>{paragraph}</p>) : null }

              { listHeading === '' ? null : <h4>{listHeading}</h4> }
              { Array.isArray(list) ?
                list.map(list =>
                  <ul>
                    <li>
                      {list}
                    </li>
                  </ul>) : null }
            </div>

            </section>
      )}

      <br/>

      <nav className="max-800" style={{ paddingTop: 15, paddingBottom: 15 }}>
        <div>
          <Link to="/">← Back</Link>
        </div>

        <div>
          <p style={{ marginTop: 24 }}>
            <a
              className="button"
              href={caseStudy.link}
              target="_blank"
              rel="noopener noreferrer">
              See prototype
            </a>
          </p>
        </div>
      </nav>

    </div>
  )
}
