import React from 'react';
import WorkCards from '../Data/WorkCards';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

export default function Post({ match }) {
  const workCard = WorkCards.find(({ id }) => id == match.params.workId)

  return (
    <div className="post">
      <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content={workCard.title} />
          <title>{workCard.title} - Web Development Portfolio by Miko Gonzaga</title>
      </Helmet>

      <nav className="max-800" style={{ paddingTop: 15, paddingBottom: 15 }}>
        <div>
          <Link to="/">← Back</Link>
        </div>
      </nav>

      {workCard.intro.map(({
        img,
        postImg,
        technologies,
        tools,
        duration,
        location }) =>
          <section className="intro animate fadeIn">
            <div className="header-intro max-800">
              <span className={`${workCard.id}`}></span>
              <h2>{workCard.title}</h2>
              <h4>{workCard.subtitle}</h4>
            </div>

            <div className="max-1200">
              { workCard.postImg === '' ? null :
                <div>
                  <img src={workCard.postImg.url} alt={workCard.postImg.alt} />
                  <small>{workCard.postImg.caption}</small>
                </div> }
            </div>

            <div className="sub-intro max-800">
              <div>
                <h3>Technologies</h3>
                { Array.isArray(technologies) ? technologies.map(technologies => <p>{technologies}</p>) : null }
              </div>

              <div>
                <h3>Tools</h3>
                { Array.isArray(tools) ? tools.map(tools => <p>{tools}</p>) : null }
              </div>

              <div>
                <h3>Duration</h3>
                { duration === '' ? null : <p>{duration}</p> }
              </div>

              <div>
                <h3>Location</h3>
                { location === '' ? null : <p>{location}</p> }
              </div>
            </div>
          </section>
      )}

      {workCard.content.map(({
        heading,
        paragraph,
        img,
        listHeading,
        list,
        links }) =>
          <section className="content animate fadeIn">
            <div className="max-800">
              { heading === '' ? null : <h2>{heading}</h2> }
            </div>

            { img.url === '' ? null :
              <div className="max-1200">
                <img src={img.url} alt={img.alt} />
                <small>{img.caption}</small>
              </div> }
            
            <div className="max-800 mb-60">
              { paragraph && paragraph.length > 0 ?
                  paragraph.map(paragraph =>
                    <p>{paragraph}</p>) : null }

              { listHeading === '' ? null : <h4>{listHeading}</h4> }

              <ul>
                { list && list.length > 0 ?
                  list.map(list =>
                    <li>
                      {list}
                    </li>) : null }
              </ul>

              <ul>
                { links && links.length > 0 ?
                  links.map(links =>
                    <li>
                      <a target="_blank" href={links}>{links}</a>
                    </li>) : null }
              </ul>
            </div>

            </section>
      )}

      <br/>

      <nav className="max-800" style={{ paddingTop: 15, paddingBottom: 15 }}>
        <div>
          <Link to="/">← Back</Link>
        </div>

        <div>
          <p style={{ marginTop: 24 }}>
            <a
              className="button"
              href={workCard.link}
              target="_blank"
              rel="noopener noreferrer">
              See company
            </a>
          </p>
        </div>
      </nav>

    </div>
  )
}