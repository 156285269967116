import addon from '../../images/addon/addon-thumb.png'
import addonHeader from '../../images/addon/addon-header.png'
import addonChallenges from '../../images/addon/addon-challenges.png'
import addonGA from '../../images/addon/addon-ga.png'
import prolineOptions from '../../images/addon/prolineoptions.png'

import edco from '../../images/edco/edco-thumb.png'
import edcoDesktop from '../../images/edco/edco-desktop.png'
import edcoBeforeAfter from '../../images/edco/before-after.png'
import edcoHeader from '../../images/edco/edcoHeader.png'

import crowdplatThumb from '../../images/crowdplat/crowdplat-thumb.png'
import crowdplatHeader from '../../images/crowdplat/crowdplat-header.png'
import crowdplanceHeader from '../../images/crowdplat/crowdplance-header.png'
import crowdplatIcons from '../../images/crowdplat/crowdplat-icons.png'

import proline from '../../images/addon/proline-thumb.png'
import prolineHeader from '../../images/addon/proline-header.png'

import sn from '../../images/addon/sn-thumb.png'
import snHeader from '../../images/addon/sn-header.png'

// import vinylThumb from '../../images/vinyl/vinyl-thumb.png'
// import vinylHeader from '../../images/vinyl/vinyl-header.png'
// import vinylSocc from '../../images/vinyl/vinyl-socc.png'
// import vinylFeatures from '../../images/vinyl/vinyl-features.png'

import acornThumb from '../../images/acorn/acorn-thumb.png'
import acornHeader from '../../images/acorn/acorn-header.png'

import csuHeader from '../../images/csu/csu-thumb.png'
import csuScore from '../../images/csu/csu.png'

// import faaHeader from '../../images/faa/faa.png'


const WorkCards = [
  //FAA
  // {
  //   id: 'faa',
  //   link: 'https://www.faa.gov',
  //   title: 'Federal Aviation Administration',
  //   subtitle: 'Developing digital learning content for pilots and aviation professionals',
  //   role: 'Content Creator',
  //   cta: 'Read more',
  //   img: {
  //     url: faaHeader,
  //     alt: 'FAA website landing page'
  //   },
  //   postImg: {
  //     url: faaHeader,
  //     alt: 'FAA website landing page',
  //     caption: ''
  //   },
  //   intro: [
  //     {
  //       duration: 'November 2022 - Present',
  //       technologies: ['HTML5', 'CSS3', 'JavaScript', 'Sharepoint'],
  //       tools: ['Course Avenue', 'Microsoft Suite'],
  //       location: 'Oklahoma, OK (Remote)'
  //     }
  //   ],
  //   content: [
  //     {
  //       heading: 'Overview',
  //       img: {
  //         url: '',
  //         alt: '',
  //         caption: ''
  //       },
  //       paragraph: [
  //         'I\'m currently working part time supporting the ESC (Enterprise Services Center) team developing learning content for pilots and aviation professionals.'
  //       ],
  //       listHeading: '',
  //       list: [],
  //       links: []
  //     }
  //   ]
  // },
  // CSU
  {
    id: 'csu',
    link: 'https://calstate.edu',
    title: 'California State University',
    subtitle: 'Managing web accessibility optimization for the California State University',
    role: 'Web Developer',
    cta: 'Read more',
    img: {
      url: csuHeader,
      alt: 'California State University thumbnail'
    },
    postImg: {
      url: csuScore,
      alt: 'California state university student studying on campus',
      caption: 'Accessibility scores from March - September 2022'
    },
    intro: [
      {
        duration: '3/2022 - 9/2022',
        technologies: ['HTML5', 'CSS3', 'JavaScript', 'Sharepoint'],
        tools: ['Siteimprove', 'Slack & Zoom', 'Google Developer Tools'],
        location: 'Long Beach, CA (Hybrid)'
      }
    ],
    content: [
      {
        heading: 'Overview',
        img: {
          url: '',
          alt: '',
          caption: ''
        },
        paragraph: [
          'In March 2022, I contracted with Robert Half to improve the web accessibility score for California State University\'s main website. I worked within a cross functional team in a hybrid setting with weekly standups and communication.'
        ],
        listHeading: 'Challenges',
        list: [
          'Work within SiteImprove to increase the web accessibility score',
          'Resolve thousands of accessibility issues within 7,000+ pages',
          'Perform automated/manual testing and ensure quality control'
        ],
        links: []
      },
      {
        heading: 'Process',
        img: {
          url: '',
          alt: '',
          caption: ''
        },
        paragraph: [
          'The CSU website utilized the SiteImprove platform. Daily, I ran automated and manual testing on thousands of pages while refactoring frontend code within Sharepoint. I kept the team updated on progress during weekly stand ups and I wrote technical documentation for specific solutions.'
        ],
        listHeading: '',
        list: [],
        links: []
      },
      {
        heading: 'Results',
        img: {
          url: '',
          alt: '',
          caption: ''
        },
        paragraph: [
          'Individually, I improved the website\'s web accessibility score from an initial basis of 58.7 points to an industry benchmark target of 84.5 points. I completed the project within a six month period and resolved thousands of accessibility issues for 1M+ visitors.'
        ],
        listHeading: '',
        list: [],
        links: []
      }
    ]
  },
  // Vinyl
  // {
  //   id: 'vinyl',
  //   link: 'https://vinylagency.com',
  //   title: 'Vinyl Agency',
  //   subtitle: 'Custom Wordpress development for New Jersey based organizations',
  //   role: 'Wordpress Developer',
  //   cta: 'Read more',
  //   img: {
  //     url: vinylThumb,
  //     alt: 'Vinyl agency thumbnail'
  //   },
  //   postImg: {
  //     url: vinylHeader,
  //     alt: 'Vinyl agency websites ',
  //     caption: 'Southern Ocean Chamber of Commerce & New Jersey Conserve Wildlife Foundation'
  //   },
  //   intro: [
  //     {
  //       duration: '1/2022 - 2/2022',
  //       technologies: ['Wordpress CMS', 'HTML5', 'CSS3/SASS (Bootstrap)', 'JavaScript', 'PHP'],
  //       tools: ['Git/Github', 'Slack & Zoom', 'Monday', 'Google Developer Tools'],
  //       location: 'New Jersey (Remote)'
  //     }
  //   ],
  //   content: [
  //     {
  //       heading: 'Overview',
  //       img: {
  //         url: '',
  //         alt: '',
  //         caption: ''
  //       },
  //       paragraph: [
  //         'Vinyl Agency is a creative development group that focuses on Wordpress, Shopify, and Digital Marketing strategy for a wide range of businesses. I worked with the team in January 2022 as a freelance Wordpress developer.'
  //       ],
  //       listHeading: '',
  //       list: []
  //     },
  //     {
  //       heading: 'Southern Ocean Chamber of Commerce',
  //       img: {
  //         url: vinylSocc,
  //         alt: 'Southern Ocean Chamber of Commerce Events Calendar',
  //         caption: 'Southern Ocean Chamber of Commerce Events Calendar'
  //       },
  //       paragraph: [
  //         'Southern Ocean Chamber of Commerce needed a new events calendar form where public users can submit and create new events. For this project, I created a new Wordpress CMS instance locally using LocalWP and pushed updates to a staging server using FTP as well as MigrateDB for database updates.',
  //         'In about two weeks, I created a customized Events Calendar with sort filters, an event submission form, and a footer clone using HTML5 & CSS3. I also added active states, and a mobile navigation using vanilla JavaScript for quicker page load speed. The new section for the website was launched early February 2022.'
  //       ],
  //       listHeading: 'Challenges',
  //       list: [
  //         'Develop an events calendar and event submission form for public users',
  //         'Customize Events Calendar plugin filters and migrate existing events',
  //         'Add active states and mobile navigation with vanilla JavaScript',
  //         'Create new Wordpress instance footer from VueJS(Nuxt) headless CMS footer'
  //       ]
  //     },
  //     {
  //       heading: '',
  //       img: {
  //         url: '',
  //         alt: '',
  //         caption: ''
  //       },
  //       paragraph: [
  //         ''
  //       ],
  //       listHeading: 'Website Links',
  //       list: [],
  //       links: [
  //         'https://visitlbiregion.com',
  //         'https://events.visitlbiregion.com'
          
  //       ]
  //     },
  //     {
  //       heading: 'Conserve Wildlife Foundation of New Jersey',
  //       img: {
  //         url: vinylFeatures,
  //         alt: 'Wildlife Cams & Search Features',
  //         caption: 'Wildlife Cams & Search Features'
  //       },
  //       paragraph: [
  //         ''
  //       ],
  //       listHeading: '',
  //       list: []
  //     },
  //     {
  //       heading: '',
  //       img: {
  //         url: '',
  //         alt: '',
  //         caption: ''
  //       },
  //       paragraph: [
  //         'Conserve Wildlife Foundation\'s new website was started in 2021 and I came in to assist and speed up development for new features. The Conserve Wildlife team were happy with the new changes and their website is scheduled to go live at the end of February 2022.',
  //         'During this project, I added and updated new plugins (SearchWP, Gravity Forms, The Events Calendar). I developed a new team section customizable with advanced custom fields. I also fixed the website video issues for live wildlife cams embedded from youtube.'
  //       ],
  //       listHeading: 'Challenges',
  //       list: [
  //         'Add a search feature to search and render only species pages',
  //         'Develop responsive team section using Advanced Custom Fields',
  //         'Debug youtube video embed issues, and smooth scroll functionality',
  //         'Install Gravity Forms, SearchWP, and The Events Calendar plugins'
  //       ]
  //     },
  //     {
  //       heading: '',
  //       img: {
  //         url: '',
  //         alt: '',
  //         caption: ''
  //       },
  //       paragraph: [],
  //       listHeading: 'Website Link',
  //       links: ['https://cwf.vinylagency.com']
  //     }
  //   ]
  // },
  // Crowdplat
  {
    id: 'crowdplat',
    link: 'https://crowdplat.com',
    title: 'Crowdplat',
    subtitle: 'Refactoring Crowdplat\'s non responsive dashboard internal pages',
    role: 'Front End Developer',
    cta: 'Read more',
    img: {
      url: crowdplatThumb,
      alt: 'Crowdplat home page'
    },
    postImg: {
      url: crowdplatHeader,
      alt: 'Crowdplat home page',
      caption: 'Main Dashboard (Before & After)'
    },
    intro: [
      {
        duration: '2021 - 2022',
        technologies: ['HTML5', 'CSS3 (Bootstrap)', 'JS (Angular 1.2)', 'NodeJS (Express)', 'MongoDB'],
        tools: ['Git/Github', 'Slack/Zoom', 'Google Suite', 'Google Developer Tools' ],
        location: 'San Jose, CA (Remote)'
      }
    ],
    content: [
      {
        heading: 'Overview',
        img: {
          url: '',
          alt: '',
          caption: ''
        },
        paragraph: [
          'Crowdplat provides solutions for enterprise businesses through Project Based Crowdsourcing, R&D Innovation, Technical and White Label Solutions. I started with a four week contract and I collaborated with project managers, designers, and developers.'
        ],
        listHeading: 'Challenges',
        list: [
          'Launch Crowdplat\'s company website following figma design mockups',
          'Develop non responsive internal dashboard pages for two platforms',
          'Update NPM modules to run with current Node version',
          'Refactor third party legacy code with frontend bugs throughout the application'    
        ]
      },
      {
        heading: 'Development & Design',
        img: {
          url: crowdplanceHeader,
          alt: 'Crowdplance Dashboard',
          caption: 'Profile Section (Before & After)'
        },
        paragraph: [
          'During the project, I updated and cleaned up Crowdplat\'s codebase and I completed development for their company website. I refactored dozens of internal dashboard pages for their internal applications, and I updated MEAN stack NPM modules to run with the current NodeJS version. I communicated daily in an agile/scrum environment and wrote documentation for future reference.',
          'For the design of the internal pages, I added conventional elements such as sidebars, mobile navigation, modern card layouts, SVG icons, and active states. The team wanted to keep all original branding and design and I was limited with design creativity. Due to time and budget issues, I mainly took direction from the stakeholders.'
        ],
        listHeading: '',
        list: []
      },
      {
        heading: '',
        img: {
          url: crowdplatIcons,
          alt: '',
          caption: 'Customized SVG Icons'
        },
        paragraph: [
          ''
        ],
        listHeading: '',
        list: []
      },
      {
        heading: 'Completion',
        img: {
          url: '',
          alt: '',
          caption: ''
        },
        paragraph: [
          'My contract with Crowdplat concluded in January 2022. The entire team I collaborated and worked with was laid off due to funding issues. I enjoyed working and contributing to the company platforms and improving the user experience for 5,000+ users respectively.'
        ],
        listHeading: '',
        list: []
      }
    ]
  },
  // Proline
  {
    id: 'proline',
    link: 'https://prolineoptions.com',
    title: 'Proline Options',
    subtitle: 'Developing a VueJS application website',
    role: 'Front End Developer',
    cta: 'Read more',
    img: {
      url: proline,
      alt: 'proline options logo'
    },
    postImg: {
      url: prolineHeader,
      alt: 'Proline Options',
      caption: ''
    },
    intro: [
      {
        duration: '2018 - 2019',
        technologies: [ 'HTML5', 'CSS3/SCSS (Bootstrap)', 'JavaScript (VueJS, NodeJS)' ],
        tools: [ 'Git/Github', 'Trello/Skype', 'Google Developer Tools' ],
        location: 'Tustin, CA'
      }
    ],
    content: [
      {
        heading: 'Overview',
        paragraph: [
          'Proline Options provides fiber optic solutions and network hardware. During a transition, their team wanted to update the company website which was managed by a Russian group (Saritasa) in Kazakhstan. I was contracted to manage the project and complete their VueJS application website.'
        ],
        listHeading: 'Challenges',
        list: [
          'Learn VueJS',
          'Complete website application updates',
          'Collaborate with a global team in Kazakhstan'
        ],
        img: {
          url: '',
          alt: '',
          caption: ''
        }
      },
      {
        heading: 'Process',
        paragraph: [
          'Learning a new JavaScript framework inpsired my curiosity and I was eager to study. Using online resources, I started learning how to develop in the VueJS environment. I communicated daily with the team and developed a great working relationship.',
          'To upload revisions, I pushed commits to a remote git branch for Proline which was reviewed before being merged into production. Custom layouts were already defined which made design decisions easier. I mainly worked creating new pages and adjusting CSS using Vue server side rendering.'
        ],
        listHeading: '',
        list: [],
        img: {
          url: '',
          alt: '',
          caption: ''
        }
      },
      {
        heading: '',
        img: {
          url: prolineOptions,
          alt: 'Home, Eval Request, Contact Us (Landing Pages)',
          caption: 'Home, Eval Request, Contact Us (Landing Pages)'
        },
        paragraph: [
          ''
        ],
        listHeading: '',
        list: []
      },
      {
        heading: 'Outcome',
        paragraph: [
          'Proline Options new website launched September 2018 and I started to test the website with users. There was a positive reaction from the stakeholders. I took feedback and continued updating website landing pages, navigation, and SEO.'
        ],
        listHeading: '',
        list: [],
        img: {
          url: '',
          alt: '',
          caption: ''
        }
      }
    ]
  },
  // Addon
  {
      id: 'addon',
      link: 'https://addonnetworks.com',
      title: 'AddOn Networks',
      subtitle: 'Refactoring a website app for mobile which led to a 56% increase in users',
      role: 'Front End Developer',
      description: 'At AddOn, I supported the marketing department by managing and coordinating a development team from Kazakhstan. I developed features for internal sales flow tracking, and created web media for partner companies.',
      cta: 'Read more',
      img: {
        url: addon,
        alt: 'addon logo'
      },
      postImg: {
        url: addonHeader,
        alt: 'AddOn Networks Header',
        caption: ''
      },
      intro: [
        {
          duration: '2018 - 2020',
          technologies: [ 'HTML5', 'CSS3 (Bootstrap)', 'JavaScript (jQuery)', 'Proprietary MVC (ASP.Net)' ],
          tools: [ 'Git/Github', 'Trello/Skype', 'Google Suite', 'Google Developer Tools' ],
          location: 'Tustin, CA'
        }
      ],
      content: [
        {
          heading: 'Overview',
          img: {
            url: '',
            alt: '',
            caption: ''
          },
          paragraph: [
            'AddOn Networks develops computer network upgrades and connectivity hardware. Initially, I was brought on board on a one month contract. In that timeframe, I collaborated closely with the sales and global marketing teams developing and refactoring front end code.'
          ],
          listHeading: 'Challenges',
          list: [
            'Refactor AddOn web application for mobile responsive view.',
            'Ensure new updates are isolated and other elements on the site aren’t affected.',
            'Cross check viewport breakpoints ensuring mobile responsiveness.'
          ]
        },
        {
          heading: 'Saritasa',
          img: {
            url: '',
            alt: '',
            caption: ''
          },
          paragraph: [
            'One of my first tasks were to coordinate and collaborate with Saritasa, a software agency that managed AddOn’s database. I worked closely with their Kazakhstan team to implement new features and requests from AddOn stakeholders.',
          ],
          listHeading: '',
          list: []
        },
        {
          heading: 'Research',
          img: {
            url: addonChallenges,
            alt: 'Addon Networks smartphone view',
            caption: 'Original Header and Footer View on Smartphones'
          },
          paragraph: [
            'The research process involved inspecting the codebase to identify underlying issues and ensure new updates would not affect other elements on the site. After surveying all the connections on the frontend throughout the application, I felt comfortable proceeding to the testing and experimentation phase.',
            'Initially, testing began on the main home page starting with the navigation, search bar, carousel and card components. During this phase, I learned more about the architecture of the code and generated a solution that would also keep the original design in place.'
          ],
          listHeading: '',
          list: []
        },
        {
          heading: 'Solution',
          img: {
            url: '',
            alt: '',
            caption: ''
          },
          paragraph: [
            'My approach was to remove all static width elements and adjust viewport layout with custom media queries. Some difficulty ensued since many instances of static widths were referenced throughout the code. Also, many elements were positioned absolutely which added to the difficulty. There were many trial and error adjustments.',
            'To keep the codebase clean - I created new CSS class instances for individual sections and elements. Customized media queries were used to declare and implement the new custom views and class instances.',
            'Eventually, I refactored the entire web application which took several weeks. The mobile responsive update made the site look decent on mobile which led to increased web sessions, new leads, and overall business growth.'
          ],
          listHeading: '',
          list: []
        },
        {
          heading: 'Results',
          img: {
            url: addonGA,
            alt: 'Google Analytics mobile overview',
            caption: 'Google Analytics mobile overview'
          },
          paragraph: [
            'Increased mobile engagement for users, new users, and sessions.',
          ],
          listHeading: '',
          list: [
            'Users: ↑ 56%',
            'New Users: ↑ 56%',
            'Sessions: ↑ 48%'
          ]
        },
      ],
  },
  // Edco 
  // {
  //   id: 'edco',
  //   link: 'https://www.linkedin.com/company/ed-co/',
  //   title: 'Edco',
  //   subtitle: 'Developing & refactoring Edco\'s internal dashboard for mobile',
  //   role: 'Front End Developer',
  //   description: 'Edco is a fundraising platform for K-12 schools. I worked closely with project managers, and developers to update, cleanup, and debug CSS Bootstrap code. I restyled landing pages, forms, and content to brand guidelines and assisted with the Edco platform launch, September 2019.',
  //   cta: 'Read more',
  //   img: {
  //     url: edco,
  //     alt: 'edco logo'
  //   },
  //   postImg: {
  //     url: edcoHeader,
  //     alt: 'Edco Header',
  //     caption: ''
  //   },
  //   intro: [
  //     {
  //       duration: '2/2019 - 9/2019',
  //       technologies: [ 'HTML5', 'CSS3 (Bootstrap)', 'JavaScript (jQuery)', 'Java (Spring)' ],
  //       tools: [ 'Git/Github', 'Trello', 'Google Suite', 'Google Developer Tools' ],
  //       location: 'New York, NY (Remote)'
  //     }
  //   ],
  //   content: [
  //     {
  //       heading: 'Overview',
  //       img: {
  //         url: '',
  //         alt: '',
  //         caption: ''
  //       },
  //       paragraph: [
  //         'Edco is a fundraising platform for K-12 schools based in New York. The Edco platform allows users to raise funds, create team pages, invite members, and promote their fundraising activities.',
  //         'I onboarded during the development phase to complete the internal dashboard mobile views. The initial developer departed midway and did not implement the Bootstrap (CSS Framework) correctly, leaving many issues and bugs on the frontend.'
  //       ],
  //       listHeading: 'Challenges',
  //       list: [
  //         'Refactor mobile web application internal dashboard pages.',
  //         'Develop and complete critical pages before September launch.',
  //         'Code cleanup, mobile testing and debugging.'
  //       ]
  //     },
  //     {
  //       heading: 'Outcome',
  //       img: {
  //         url: edcoBeforeAfter,
  //         alt: 'edco before and after',
  //         caption: 'Find/Create Team Page (Before & After)',
  //       },
  //       paragraph: [
  //         'Edco utilizes a Java backend (Spring framework) where I worked around and cleaned up the CSS Bootstrap code. I simplified and ensured that elements utilizing Bootstrap v3 features were written using common practices. ',
  //         'I made sure internal dashboard pages were consistent across smartphone viewports, tablets, and desktops. There were 20+ pages to develop and launch for production. Rigorous testing and updates were performed to clean up frontend issues.'
  //       ],
  //       listHeading: '',
  //       list: []
  //     },
  //     {
  //       heading: '',
  //       img: {
  //         url: edcoDesktop,
  //         alt: 'edco desktop',
  //         caption: ''
  //       },
  //       paragraph: [
  //         ''
  //       ],
  //       listHeading: '',
  //       list: []
  //     },
  //     {
  //       heading: 'Takeaway',
  //       img: {
  //         url: '',
  //         alt: '',
  //         caption: ''
  //       },
  //       paragraph: [
  //         'During the project, I leveraged and strengthed my frontend development knowledge specifically with jQuery and Bootstrap. We managed the project with Google Suite Tools and communicated consistently which was essential for launch.'
  //       ],
  //       listHeading: '',
  //       list: []
  //     },
  //     {
  //       heading: 'Completion',
  //       img: {
  //         url: '',
  //         alt: '',
  //         caption: ''
  //       },
  //       paragraph: [
  //         'The Edco project concluded at the end of summer and launched September 2019. The fundraising platform is based in New York, NY.'
  //       ],
  //       listHeading: '',
  //       list: []
  //     },
  //     {
  //       heading: '',
  //       img: {
  //         url: '',
  //         alt: '',
  //         caption: ''
  //       },
  //       paragraph: [
  //         ''
  //       ],
  //       listHeading: '',
  //       list: []
  //     }
  //   ]
  // },
  // Acorn
  {
    id: 'acorn',
    link: 'https://www.linkedin.com/company/acornmarketing/about/',
    title: 'Acorn Marketing',
    subtitle: 'Developing Customized Bed & Breakfast Wordpress Websites',
    role: 'Wordpress Developer',
    cta: 'Read more',
    img: {
      url: acornThumb,
      alt: 'Acorn Internet Services thumbnail'
    },
    postImg: {
      url: acornHeader,
      alt: 'Iris Inn & Tropical Inn',
      caption: 'Iris Inn & Tropical Inn'
    },
    intro: [
      {
        duration: '2/2018 - 10/2019',
        technologies: ['Wordpress CMS', 'HTML5 & CSS3'],
        tools: ['Skype, FTP', 'Google Developer Tools'],
        location: 'Colorado Springs, CO (Remote)'
      }
    ],
    content: [
      {
        heading: 'Overview',
        img: {
          url: '',
          alt: '',
          caption: ''
        },
        paragraph: [
          'Acorn Marketing develops responsive websites, hosting, and marketing services for Bed & Breakfast Innkeepers and Propery Managers. I was contracted as a freelance frontend developer and I started working with the team February 2018. I collaborated weekly with project managers, graphic designers, and backend developers to create dozens of Bed & Breakfast websites all across the United States.'
        ],
        listHeading: 'Challenges',
        list: [
          'Complete PSD to HTML website development under tight deadlines',
          'Develop custom media queries for responsive landing pages, calendars, and forms',
          'Utilize Advanced Custom Fields for client customization'
        ]
      },
      {
        heading: 'Takeaway',
        img: {
          url: '',
          alt: '',
          caption: ''
        },
        paragraph: [
          'I learned how to develop custom Wordpress websites and I increased my knowledge within the Wordpress ecosystem. Working remotely for the first time, I was extremely productive and I delivered high quality digital websites for owners and their customers.'
        ],
        listHeading: 'Website Links',
        links: [
          'https://www.irisinn.com/',
          'https://www.tropicalinn.com/'
        ]
      }
    ]
  },
  // Smith & Noble
  {
    id: 'sn',
    link: 'https://smithandnoble.com',
    title: 'Smith & Noble',
    subtitle: 'Supporting email campaigns which reached 600k+ customers weekly',
    role: 'Jr. Front End Developer, Email Marketing',
    cta: 'Read more',
    img: {
      url: sn,
      alt: 'smith and noble logo'
    },
    postImg: {
      url: snHeader,
      alt: 'smith and noble work',
      caption: ''
    },
    intro: [
      {
        duration: '9/2017 - 1/2018',
        technologies: [ 'HTML5', 'CSS3 (Bootstrap)', 'JavaScript (jQuery)', 'Smarty Templates' ],
        tools: [ 'Asana/Slack', 'Bronto/Hotjar', 'Adobe Photoshop', 'Google Developer Tools' ],
        location: 'Corona, CA'
      }
    ],
    content: [
      {
        heading: 'Overview',
        img: {
          url: '',
          alt: '',
          caption: ''
        },
        paragraph: [
          'Smith & Noble is a custom window design and treatment company in Corona, CA. While working, I collaborated with the creative design and marketing teams to assist and support their online outreach. I developed landing pages for weekly campaigns, created HTML email templates, and ran web A/B testing to analyze customer behavior.'
        ],
        listHeading: 'Challenges',
        list: [
          'Complete weekly landing page deadlines to support email marketing campaigns',
          'Develop HTML email templates within Bronto',
          'Collaborate with marketing and creative design teams',
          'Utilize hotjar for landing page A/B testing'
        ]
      },
      {
        heading: 'Takeaway',
        img: {
          url: '',
          alt: '',
          caption: ''
        },
        paragraph: [
          'In a six month period (Internship), I created dozens of landing pages, email templates, and I learned how to collaborate and work within a team. Witnessing my work reach hundreds of thousands of users boosted my confidence, inspiration, and motivation.'
        ],
        listHeading: '',
        list: []
      },
    ]
  }
]

export default WorkCards
