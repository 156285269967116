import thumbnail from '../../images/laperles/thumbnail.png'
import laperlesHeader from '../../images/laperles/laperlesHeader.png'
import before from '../../images/laperles/before.png'
import diamond from '../../images/laperles/diamond.png'
import cc from '../../images/laperles/cc.png'
import sitemap from '../../images/laperles/sitemap.png'
import cardsort from '../../images/laperles/cardsort.png'
import remy from '../../images/laperles/remy.png'
import graySketches from '../../images/laperles/grayscale-sketches.png'
import grayProducts from '../../images/laperles/grayscale-products.png'
import checkout from '../../images/laperles/checkout.png'
import colebrook from '../../images/laperles/colebrook.png'

import thumbnailChopra from '../../images/chopra/thumbnailChopra.png'
import chopraHeader from '../../images/chopra/chopraHeader.png'
import chopraAffinity from '../../images/chopra/affinity.png'
import chopraCC from '../../images/chopra/cc.png'
import chopraData from '../../images/chopra/data.png'
import chopraHifi from '../../images/chopra/hifi.png'
import chopraOG from '../../images/chopra/og.png'
import chopraSitemap from '../../images/chopra/sitemap.png'
import chopraSketches from '../../images/chopra/sketches.png'
import chopraNicole from '../../images/chopra/nicole.png'

const CaseStudies = [
  {
    id: 'chopra',
    link: 'https://www.figma.com/proto/bd7AgvILgOfLWwvwfpRfcS/CHOPRA?node-id=350%3A1473&scaling=scale-down&page-id=219%3A536&starting-point-node-id=350%3A1473',
    title: 'Chopra',
    subtitle: 'Designing new features for the Chopra mobile app',
    role: 'Role: UI/UX Design',
    description: '',
    cta: 'Read more',
    img: {
      url: thumbnailChopra,
      alt: 'chopra thumbnail'
    },
    postImg: {
      url: chopraHeader,
      alt: 'chopra thumbnail',
      caption: ''
    },
    intro: [
      {
        duration: '8/2021',
        team: ['Miko Gonzaga', 'Gabriel Kenny'],
        role: ['UI/UX Design'],
        location: 'Remote'
      }
    ],
    content: [
      {
        heading: 'Overview',
        img: {
          url: '',
          alt: '',
          caption: ''
        },
        paragraph: [
          'Our team conducted user research to find pain points for Chopra\'s mobile app. During this project, I led the user research segment and contributed to the clickable prototype. I specifically designed the navigation, search page, and meditation player & playlist.'
        ],
        listHeading: '',
        list: []
      },
      {
        heading: 'Problem',
        img: {
          url: '',
          alt: '',
          caption: ''
        },
        paragraph: [
          'The Chopra mobile app lacked features such as Search Functionality, Less Guidance Meditations, and Free Content for their users.'
        ],
        listHeading: '',
        list: []
      },
      {
        heading: 'Design Process',
        img: {
          url: diamond,
          alt: 'Double Diamond design process',
          caption: 'Discover, Define, Ideate, Deliver'
        },
        paragraph: [
          'We used the double diamond model for the end to end design process. We started in the Discover phase where we gathered user data and filtered the results in the Define stage. Next, we Ideated and Delivered our design solutions.'
        ],
        listHeading: '',
        list: []
      },
      {
        heading: 'Screener Survey',
        img: {
          url: chopraData,
          alt: 'Chopra Data',
          caption: 'Age & Gender Demographics'
        },
        paragraph: [
          'Our first challenge was to uncover how users deal with stress and interactions with meditation apps. To start off, I created a six question screener survey to gather qualitative and quantitative data. The survey also served as a recruiting funnel where we contacted interested participants for later round user interviews.'
        ],
        listHeading: 'Demographic Insights',
        list: [
          '93% Gen Y & Gen Z',
          '63% do not use meditation apps',
          '70% Female'
        ]
      },
      {
        heading: 'User Interviews',
        img: {
          url: '',
          alt: '',
          caption: ''
        },
        paragraph: [
          'After several days, we gathered a total of 28 responses. From the group, we interviewed four qualified candidates where we uncovered meditation insights. Valuable qualitative and quantitative data also was collected from the screener survey and user interviews.'
        ],
        listHeading: 'Key Findings',
        list: [
          'Chopra doesn\’t have a search feature',
          'Experienced meditators want Less Guidance meditations within Chopra',
          'Calm & Headspace are their top competitors'
        ]
      },
      {
        heading: 'Affinity Mapping',
        img: {
          url: chopraAffinity,
          alt: 'Chopra Affinity Mapping',
          caption: 'Screener Survey & iOS App Store Reviews (Affinity Map)'
        },
        paragraph: [
          'After gathering data from the screener survey and user interviews, we affinity mapped the responses which uncovered positive and negative trends. My teammate affinity mapped the reviews from the iOS app store while I focused on my survey data.'
        ],
        listHeading: 'Negative Trends',
        list: [
          'Lack of features',
          'No search functionality',
          'Limited free content'
        ]
      },
      {
        heading: '',
        img: {
          url: '',
          alt: '',
          caption: ''
        },
        paragraph: [
          ''
        ],
        listHeading: 'Positive Trends',
        list: [
          'Better concentration',
          'Improved self-esteem',
          'Lower stress & anxiety'
        ]
      },
      {
        heading: 'Feature Analysis',
        img: {
          url: chopraCC,
          alt: 'Chopra Competitive Analysis',
          caption: 'Feature Analysis'
        },
        paragraph: [
          'Next, I compared Chopra features with their competitors to gain a better insight on what could be improved. Clear competitors in the market were Calm, Headspace, and Insight Timer which were widely used by many of their users. The feature analysis uncovered areas where Chopra can improve competitively.'
        ],
        listHeading: 'Key Insights',
        list: [
          'Lack of features compared to Calm, Headspace, and Insight Timer',
          'Popular features such as Search, Sleep, and Less Guidance are not present'
        ]
      },
      {
        heading: 'Persona',
        img: {
          url: chopraNicole,
          alt: 'Chopra Persona',
          caption: 'Nicole Shaw (Target User)'
        },
        paragraph: [
          'The survey and user interviews led to Nicole Shaw who is the target user for the app. She is an experienced meditation app user who uses the app in the morning. Before work, she uses the app for 30 minutes which makes a great difference in her day.'
        ],
        listHeading: '',
        list: []
      },
      {
        heading: 'Sitemap',
        img: {
          url: chopraSitemap,
          alt: 'Chopra Sitemap',
          caption: 'Chopra Sitemap'
        },
        paragraph: [
          'After reviewing the initial content, I created a sitemap that showcased the new feature pages for meditate and search. We kept the original structure for the Profile, Library, and Home pages for their current users.'
        ],
        listHeading: '',
        list: []
      },
      {
        heading: 'Current Chopra App',
        img: {
          url: chopraOG,
          alt: 'Chopra Original Design',
          caption: 'Current Chopra App (Home, Library, & Profile pages)'
        },
        paragraph: [
          ''
        ],
        listHeading: '',
        list: []
      },
      {
        heading: 'Sketching & Ideation',
        img: {
          url: chopraSketches,
          alt: 'Chopra Sketches',
          caption: 'Early Ideation'
        },
        paragraph: [
          'Working collaboratively, we decided to keep a similar design convention and address user pain points. We designed the new features using variations from their competitors.'
        ],
        listHeading: '',
        list: [
          'Search Feature: Search was a pain point from our user research which we wanted to address. We decided to tackle the design solution for the search and explore page where users can find new features for the Chopra app.',
          'Less Guidance: Less Guidance was a feature experienced meditators were telling us they wanted. This allows for less talk during the meditation, a feature that Chopra lacks for their ex users.',
          'Navigation: We updated the navigation component where we added new pages for Meditate and Search using common design conventions.'
        ]
      },
      {
        heading: 'Outcome',
        img: {
          url: chopraHifi,
          alt: 'Chopra high fidelity wireflows',
          caption: 'High Fidelity Wireflows'
        },
        paragraph: [
          'We tested four users specifically focused on the Search feature and how they navigated to the Less Guidance meditations. We found that most users navigated to the Less Guidance meditations through the onboarding promotion on home page load.'
        ],
        listHeading: 'Results',
        list: [
          '4/4 users were able to use the Search feature in 2 clicks',
          '3/4 users navigated to the Less Guidance meditations in under 15 seconds'
        ]
      },
      {
        heading: '',
        img: {
          url: '',
          alt: '',
          caption: ''
        },
        paragraph: [
          ''
        ],
        listHeading: 'Next Steps',
        list: [
          'Test more users for additional features',
          'Expand Free Meditation content'
        ]
      },
    ]
  },
  {
      id: 'laperles',
      link: 'https://www.figma.com/proto/sdvAq6BLiqU4iA6qe9W6VG/MG-P2?node-id=237%3A3780&scaling=min-zoom',
      title: 'LaPerles IGA',
      subtitle: 'Designing LaPerles IGA E-Commerce checkout flow',
      role: 'Role: UI/UX Design',
      description: '',
      cta: 'Read more',
      img: {
        url: thumbnail,
        alt: 'LaPerles IGA thumbnail'
      },
      postImg: {
        url: laperlesHeader,
        alt: 'laperles header image',
        caption: ''
      },
      intro: [
        {
          duration: '7/2021',
          team: ['Miko Gonzaga'],
          role: ['UI/UX Design'],
          location: 'Remote'
        }
      ],
      content: [
        {
          heading: 'Overview',
          paragraph: [
            'LaPerle’s IGA is a locally owned grocery store in Colebrook, NH. During this project, I redesigned their E-Commerce checkout flow and Sign Up process keeping their local customer base front and center.'
          ],
          listHeading: '',
          list: [],
          img: {
            url: '',
            alt: '',
            caption: ''
          }
        },
        {
          heading: 'Problem',
          img: {
            url: '',
            alt: '',
            caption: ''
          },
          paragraph: [
            'LaPerle\’s IGA needs an easier E-Commerce checkout for their local customers.'
          ],
          listHeading: '',
          list: []
        },
        {
          heading: 'Design Process',
          img: {
            url: diamond,
            alt: 'Double Diamond Design Process',
            caption: 'Discover, Define, Ideate, Deliver'
          },
          paragraph: [
            'I used the double diamond model to guide the end to end redesign process. The discover, define, ideate, and deliver phases were instrumental for developing key insights.'
          ],
          listHeading: '',
          list: []
        },
        {
          heading: 'Market research',
          img: {
            url: colebrook,
            alt: 'Colebrook New Hampshire',
            caption: 'Colebrook, NH'
          },
          paragraph: [
            'During the discover phase, I conducted market research for Colebrook, NH. Colebrook is a small mountain town situated in the northeastern part of the United States. It\'s a haven for outdoor adventurers where you can rent ATV\’s and ride the many trails around the area.'
          ],
          listHeading: 'Local Geography',
          list: [
            'Population: 2,138',
            'Median Age: 52 years',
            'Gender: 52% Female',
            'Average Wage (Weekly): $758.20',
          ]
        },
        {
          heading: 'Feature Analysis',
          img: {
            url: cc,
            alt: 'Competitive & Comparative Analysis',
            caption: 'Feature Analysis'
          },
          paragraph: [
            'In the Define phase, I conducted feature analysis for grocers in the town. Locally, there\’s a Walgreens and Family Dollar for retail purchasing with their main competitor being North Country Marketplace. After filtering the data, I gained key insights for business growth.'
          ],
          listHeading: 'Areas for growth',
          list: ['Social Media', 'E-commerce checkout']
        },
        {
          heading: 'Proto Persona',
          img: {
            url: remy,
            alt: 'Remy Alarie (Proto Persona)',
            caption: 'Remy Alarie (Target User)'
          },
          paragraph: [
            'Since user interviews were not possible in the project timeframe, I used market analysis to create a proto persona who is the target user for LaPerle\'s. Remy Alarie was developed, and she is a married 56 year old retail manager that\'s worried about her health.'
          ],
          listHeading: '',
          list: []
        },
        {
          heading: 'Information architecture',
          img: {
            url: sitemap,
            alt: 'laperles sitemap',
            caption: 'Sitemap'
          },
          paragraph: [
            'After reviewing the initial content structure, I created a simplified sitemap that would be easier to navigate for the local users. I made sure to keep the original structure for weekly ads, recipes, and consolidated repetitive labeling.'
          ],
          listHeading: '',
          list: []
        },
        {
          heading: 'Card sort',
          img: {
            url: cardsort,
            alt: 'Open Card Sorting',
            caption: 'Open Card Sorting'
          },
          paragraph: [
            'For the navigation categories, I conducted a qualitative open card sort with four users to gain insights. Based on the feedback, I decided to use a mix of categories from the card sort as well as competitors in the area to structure the navigation categories.'
          ],
          listHeading: '',
          list: []
        },
        {
          heading: 'Task analysis',
          img: {
            url: before,
            alt: 'Laperles IGA original E-commerce flow',
            caption: 'LaPerles Current Checkout'
          },
          paragraph: [
            'After conducting a task analysis, I found their online checkout may have a high cognitive load for their customers. I felt this could be improved with a redesign.'
          ],
          listHeading: '',
          list: []
        },
        {
          heading: 'Sketches & Design',
          img: {
            url: graySketches,
            alt: 'LaPerles early ideation',
            caption: 'Early Ideation'
          },
          paragraph: [
            'Initially, I started sketching a design layout influenced by top competitors in the area. After brainstorming, I created greyscale wireframes which turned into high fidelity prototypes to test and validate with users.',
            'I worked in Figma where I used consistent design convention elements across the pages. Inspiration was taken during the Competitve and Comparative analysis phase. Keeping the local user in mind, I kept the French themed colors and initial style guide.'
          ],
          listHeading: '',
          list: []
        },
        {
          heading: 'Grayscale Wireflows',
          img: {
            url: grayProducts,
            alt: 'Grayscale Products Discovery',
            caption: 'Low Fidelity Wireframes'
          },
          paragraph: [
            ''
          ],
          listHeading: '',
          list: []
        },
        {
          heading: 'Checkout Process ⬇',
          img: {
            url: checkout,
            alt: 'Checkout process',
            caption: ''
          },
          paragraph: [
            ''
          ],
          listHeading: '',
          list: []
        },
        {
          heading: 'Usability Testing',
          img: {
            url: '',
            alt: '',
            caption: ''
          },
          paragraph: [
            'I conducted three usability test sessions to test the checkout flow. I tested the ability to checkout and create an account. The results for both tasks were positive as users were able to complete the tasks with minimal errors.'
          ],
          listHeading: '',
          list: []
        },
        {
          heading: 'Results',
          img: {
            url: '',
            alt: '',
            caption: ''
          },
          paragraph: [
            ''
          ],
          listHeading: '',
          list: [
            '3/3 users were able to ‘Create an Account’ within 21 seconds',
            '3/3 users were able to ‘View’ their account within 10 seconds',
            '2/3 users were able to ‘Add Products’ and checkout within 3 minutes',
          ]
        },
        {

          heading: 'Next Steps',
          img: {
            url: '',
            alt: '',
            caption: ''
          },
          paragraph: [
            ''
          ],
          listHeading: '',
          list: [
            'Test more users and gather additional feedback',
            'Add countdown timer for additional savings',
            'Incorporate loyalty savings program for return customers',
            'Add review sections for feedback and messaging within user accounts'
          ]
        },
        {
          heading: '',
          img: {
            url: '',
            alt: '',
            caption: ''
          },
          paragraph: [
            ''
          ],
          listHeading: '',
          list: []
        }
      ]
  }
]

export default CaseStudies
