const Home = {
    img: {
        url: '',
        alt: 'Miko Gonzaga'
    },
    name: 'Miko',
    last: 'Gonzaga',
//     pitch: 'Creative content developer with a passion for responsive frontend development and technical writing.'
}

export default Home