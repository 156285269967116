import React from 'react';
import './About.scss';
import Nav from '../Nav/Nav'
import { Helmet } from 'react-helmet';
// import { Link } from "react-router-dom";

import trestles from '../../images/trestles.png'
// import pipe from '../../images/pipe.png'

export default function About() {
    return (
        <div className="max-1000">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="description" content="About Miko Gonzaga" />
                <title>About Miko Gonzaga</title>
            </Helmet>

            <Nav />

            <section className="about animate fadeIn">
                <img
                  src={trestles}
                  alt={'Office desk'}
                  className="about-img"
                />
                <div className="quote">
                  <div>
                    <h2>My Story</h2>
                    <p>
                      We moved to the United States from the Philippines in the 1990's. My parents started out very poor with no house, car, and we lived in a home with several families. Over time, we eventually got our first apartment in Garden Grove, CA. Slowly, we started living the American dream.
                      <br /><br />
                      Through the early 2000's, I went to high school and I wanted to be a pilot since my mom worked at the airport. Unfortunately, I wasn't admitted into the Air Force Academy so I enrolled at Orange Coast College in Costa Mesa, CA. At the time, I wanted to be an elementary school teacher. 
                      <br /><br />
                      In two years, I received my AA degree and I transferred to Cal State Fullerton pursuing a psychology major. With one semester to go, I needed to start working full time and I wasn't able to complete my degree. I started working in the medical field (2008 - 2016) and I was able to start taking care of myself and living on my own.
                      <br /><br />
                    </p>

                    <h3>Discovery</h3>
                    <p>
                      In January 2016, my position at UC Irvine Medical Center was eliminated and I was laid off for the first time. Luckily, I was able to find web development and I started teaching myself front and backend development. It was a little tough at first but I found a real passion for the first time in my life.
                      <br /><br />
                      I got my first break in September 2017 and I professionally transitioned into the field. Since then, I've been working mainly as a Front End Developer creating and designing websites, landing pages, and marketing content for businesses and individuals.
                      <br /><br />
                      Currently, I'm living in Irvine, CA and I love to take care of my small family when I'm not working. I also love being a nerd. I'm into video games, music, surfing, and combat sports for some reason. 
                      <br /><br />
                      Thanks if you read this far. If you have any questions, please reach out!
                    </p>
                  </div>

                  <br />

                  <a
                    className="button"
                    href="mailto:info@mikogonzaga.com"
                    target="_blank"
                    rel="noopener noreferrer">
                    Contact
                  </a>
                </div>

                {/* <Link Link to="/">
                    <img
                      src={pipe}
                      alt={'Super Mario Brothers Pipe'}
                      className="pipe"
                    />
                </Link> */}
            </section>

            <br/><br/>

        </div>
    )
}
